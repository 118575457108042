import React from 'react';
import './Swiper.css'
import topic1 from '../../img/topic.png'


const Swiper = () => {
    return (
        <div id='natijalar' className='swiper'>
            <div className="slider">
                <div className="slide-truck">
                    <div className="slide">
                        <img clasName="swiper-img" src={topic1} alt="img " />
                    </div>
                    <div className="slide">
                        <img clasName="swiper-img" src={topic1} alt="img" />
                    </div>
                    <div className="slide">
                        <img clasName="swiper-img" src={topic1} alt="img" />
                    </div>
                    <div className="slide">
                        <img clasName="swiper-img" src={topic1} alt="img" />
                    </div>
                    <div className="slide">
                        <img clasName="swiper-img" src={topic1} alt="img" />
                    </div>
                    <div className="slide">
                        <img clasName="swiper-img" src={topic1} alt="img" />
                    </div>
                    <div className="slide">
                        <img clasName="swiper-img" src={topic1} alt="img" />
                    </div>
                    <div className="slide">
                        <img clasName="swiper-img" src={topic1} alt="img" />
                    </div>
                    <div className="slide">
                        <img clasName="swiper-img" src={topic1} alt="img" />
                    </div>
                    <div className="slide">
                        <img clasName="swiper-img" src={topic1} alt="img" />
                    </div>
                </div>
            </div>
            {/* <Swiper2 /> */}
        </div>
    );
}

export default Swiper;
